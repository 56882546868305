import React from "react"
import Layout from "../components/layout"
import Contact from "../components/contact"
import HeadLabel from "../components/head-label"
import Seo from "../components/seo"
import Button from "../components/button"
import { StaticImage } from "gatsby-plugin-image"

export default function Service() {
  return (
    <Layout>
      <Seo
        title="Service | 株式会社GoodTe | グッテ"
        description="潰瘍性大腸炎・クローン病患者とそのご家族を対象としたオンラインコミュニティ、企業向けの調査・イベント支援を提供しています。"
        ogpSiteName="Service | 株式会社GoodTeコーポレートサイト"
      />
      <div className="container mx-auto">
        <div className="max-w-4xl mx-auto mt-10">
          <HeadLabel label={"患者様向けサービス"} id="gcom" />
          <div className="px-3 pb-8 typography">
            <div className="text-lg font-bold">
              Gコミュニティ(潰瘍性大腸炎・クローン病患者とそのご家族を対象としたオンラインコミュニティ)
            </div>
            <div className="my-4">
              潰瘍性大腸炎、クローン病などの炎症性腸疾患(Inflammatory Bowel
              Disease:IBD)患者さんとそのご家族・パートナーを対象とした匿名・無料のオンラインコミュニティです。
            </div>
          </div>
          <div className="px-6 py-4 mb-5 bg-gray-100">
            <div className="">サービスの特徴</div>
            <ol className="list-decimal list-inside">
              <li className="my-4">
                IBD領域での経験豊富な消化器専門医、看護師、管理栄養士、薬剤師等の専門家に質問したり、専門家が科学的に正しい情報を発信してくれる
              </li>
              <li className="my-4">
                他の患者さんに治療、食事、就労など生活面の悩みを相談できる
              </li>
              <li className="my-4">
                登録者数は2000名を超え、難病領域では国内最大級のオンラインコミュニティ
              </li>
              <li className="my-4">患者や患者ご家族の体験談が豊富</li>
            </ol>
          </div>
          <Button
            label={"Gコミュニティへ"}
            link={"https://gcarecommunity.com/"}
            external={true}
          />
          <div className="mt-6 px-3 pb-8 typography">
            <div className="text-lg font-bold">
              グッテレシピ(IBD・過敏性腸症候群(IBS)患者さん、お腹の弱い方向けレシピサイト)
            </div>
            <div className="my-4">
              患者さんご自身が作成し管理栄養士が監修するレシピを集めたレシピサイトです。
            </div>
          </div>
          <div className="px-6 py-4 mb-5 bg-gray-100">
            <div className="">サービスの特徴</div>
            <ol className="list-decimal list-inside">
              <li className="my-4">
                IBD/IBS患者さんご自身作成のレシピが300レシピ以上！
              </li>
              <li className="my-4">
                体調や症状、気になるタグより検索することができる！
              </li>
              <li className="my-4">
                お腹に優しい低FODMAPレシピが充実！              
              </li>
              <li className="my-4">
                脂質や食物繊維など栄養成分別ソート機能付！
              </li>
              <li className="my-4">
                グッテの管理栄養士がレシピの栄養計算を行い解説！
              </li>
              <li className="my-4">
                症状に合わせたアレンジや低FODMAPレシピへのアレンジ方法を紹介！
              </li>
            </ol>
          </div>
          <Button
            label={"グッテレシピへ"}
            link={"https://goodtecommunity.com/"}
            external={true}
          />

          <div className="px-3 pb-8 typography">
            <div className="text-lg font-bold">
              やさしいひとくち(「お腹にやさしい設計ｘリッチな味わい」を実現したおやつ)
            </div>
            <div className="my-4">
              株式会社明治様主催の明治アクセラレータ―プログラムの支援を受け、Gコミュニティやお腹に不安を抱える方々と一緒に作りました。
              「あんしんｘおいしいｘたのしい」をお届けします。
            </div>
          </div>
          <div className="px-6 py-4 mb-5 bg-gray-100">
            <div className="">商品の特徴</div>
            <ol className="list-decimal list-inside">
              <li className="my-4">
                ノンフライスナック こだわりチーズ、トリュフ、カカオ味の3種をご用意！
              </li>
              <li className="my-4">
                脂質控えめ、低FODMAP、添加物不使用でお腹にやさしい！
              </li>
              <li className="my-4">
                厳選素材と独自の技術で味、香り、食感にとことんこだわり、食べることの楽しみを感じられる！
              </li>
              <li className="my-4">
                Gコミュニティやお腹に不安を抱える方と一緒に開発！
              </li>
              <li className="my-4">
                株式会社明治主催の明治アクセラレータ―プログラムが開発を全面サポート！
              </li>
            </ol>
          </div>
          <Button
            label={"やさしいひとくちブランドサイトへ"}
            link={"https://yasashii-hitokuchi.goodtecommunity.com/"}
            external={true}
          />

          <div className="text-center my-8 text-lg">運営メンバ</div>
          <div className="flex flex-wrap justify-center mx-3 mb-20">
            <div className="w-1/2 md:w-1/3 p-2">
              <StaticImage
                src="../images/rino.jpg"
                alt="Miyazaki"
                placeholder="blurred"
                layout="fullWidth"
                className="max-w-[180px] mx-auto"
              />
              <div className="typography text-center">リノ</div>
              <div className="text-sm typography px-3 mt-4 text-neutral-700">
                2017年～潰瘍性大腸炎。医療秘書として病院勤務経験あり。ダンスとカラオケと走るのが好きな人。
              </div>
            </div>

            <div className="w-1/2 md:w-1/3 p-2">
              <StaticImage
                src="../images/ran.png"
                alt="Miyazaki"
                placeholder="blurred"
                layout="fullWidth"
                className="rounded-full max-w-[180px] mx-auto"
              />
              <div className="typography text-center">ラン</div>
              <div className="text-sm typography px-3 mt-4 text-neutral-700">
                2013年、潰瘍性大腸炎発症。医療機関勤務。病気とともに前向きに生きられるように、活動をしています。
              </div>
            </div>

            <div className="w-1/2 md:w-1/3 p-2">
              <StaticImage
                src="../images/penta.jpg"
                alt="Miyazaki"
                placeholder="blurred"
                layout="fullWidth"
                className="max-w-[180px] mx-auto"
              />
              <div className="typography text-center">Penta</div>
              <div className="text-sm typography px-3 mt-4 text-neutral-700">
                2016年発症の潰瘍性大腸炎患者です。仕事は営業と総務の経験があります。趣味は料理と旅行です。
              </div>
            </div>

            <div className="w-1/2 md:w-1/3 p-2">
              <StaticImage
                src="../images/kuni.png"
                alt="kuni"
                placeholder="blurred"
                layout="fullWidth"
                className="max-w-[180px] mx-auto"
              />
              <div className="typography text-center">くに</div>
              <div className="text-sm typography px-3 mt-4 text-neutral-700">
                高校生でクローン病に罹患。看護師・保健師で一児の母。わかりやすい情報発信を目指します。
              </div>
            </div>

            <div className="w-1/2 md:w-1/3 p-2">
              <StaticImage
                src="../images/bu-chan.jpg"
                alt="bu-vhan"
                placeholder="blurred"
                layout="fullWidth"
                className="rounded-full max-w-[180px] mx-auto"
              />
              <div className="typography text-center">ぶーちゃん</div>
              <div className="text-sm typography px-3 mt-4 text-neutral-700">
                クローン病薬剤師。今この時間を病気の悩みや将来のこと考える時間に当てるのはもったいない。楽しい今日そして明日に変えるために私たちに相談してください。
              </div>
            </div>

            <div className="w-1/2 md:w-1/3 p-2">
              <StaticImage
                src="../images/natsuko.png"
                alt="Miyazaki"
                placeholder="blurred"
                layout="fullWidth"
                className="rounded-full max-w-[180px] mx-auto"
              />
              <div className="typography text-center">なっちゃん</div>
              <div className="text-sm typography px-3 mt-4 text-neutral-700">
                管理栄養士として病院勤務経験ありの2児の母。娘との女子トークが日頃の楽しみ。
              </div>
            </div>
          </div>

          <HeadLabel label={"専門家サポーター"} />
          <div className="mt-6 mb-20">
            <div className="flex">
              <div className="w-1/2 px-2 max-w-[200px]">
                <StaticImage
                  src="../images/imai.png"
                  alt="imai"
                  placeholder="blurred"
                  layout="fullWidth"
                  className="rounded-full"
                />
              </div>
              <div className="w-1/2 px-2">
                <div className="text-sm text-neutral-500">消化器専門医</div>
                <div className="text-sm text-neutral-500">医学博士 </div>
                <div className="text-2xl typography">今井 仁</div>
              </div>
            </div>
            <div className="text-sm typography px-3 mt-4 text-neutral-700">
              消化器専門医、医学博士。ミシガン大学への研究留学時に代表の宮﨑さんと知り合い、本事業に関わるようになりました。現在神奈川県の大学病院でIBDを中心に研究と臨床を行っています。病気に負けず、患者さん一人一人が自分の理想の人生を描けることを陰ながらサポートしたいと思っています。
            </div>
          </div>
          <div className="mt-6 mb-20">
            <div className="flex">
              <div className="w-1/2 px-2 max-w-[200px]">
                <StaticImage
                  src="../images/sugihara.jpg"
                  alt="Miyazaki"
                  placeholder="blurred"
                  layout="fullWidth"
                  className="rounded-full"
                />
              </div>
              <div className="w-1/2 px-2">
                <div className="text-sm text-neutral-500">
                  管理栄養士（日本）
                </div>
                <div className="text-sm text-neutral-500">栄養学博士 </div>
                <div className="text-2xl typography">杉原 康平</div>
              </div>
            </div>
            <div className="text-sm typography px-3 mt-4 text-neutral-700">
              自身のクローン病を機に、栄養学に興味を持ち、管理栄養士の道を目指す。よりIBDを専門に勉強したい、また栄養学的な立場からIBD治療に貢献したいと思い、栄養学の大学院に進学する。栄養学博士を取得後、米国ミシガン大学に研究留学し、食事とIBDの関係を明らかにする研究に従事。
            </div>
          </div>

          <HeadLabel label={"応援メッセージ"} />
          <div className="mt-6 mb-20">
            <div className="flex">
              <div className="w-1/2 px-2 max-w-[200px]">
                <StaticImage
                  src="../images/cyuto.jpg"
                  alt="Miyazaki"
                  placeholder="blurred"
                  layout="fullWidth"
                  className="rounded-full"
                />
              </div>
              <div className="w-1/2 px-2">
                <div className="text-sm text-neutral-500">
                  機能強化型認定栄養ケア・ステーション鈴鹿　代表
                </div>
                <div className="text-sm text-neutral-500">
                  松本大学大学院　非常勤講師
                </div>
                <div className="text-2xl typography">中東 真紀</div>
              </div>
            </div>
            <div className="text-sm typography px-3 mt-4 text-neutral-700">
              <div>
                IBD患者会を立ち上げて20年ほどになりますが、「安心して前向きに生きること」は、私も患者さんに伝えたい大切な言葉のひとつです。
              </div>
              <div>
                同じ時間を過ごすのであれば、前向きに笑顔で生きて欲しいのです。苦しくて悲しくて辛いことがあれば、大声で泣いてもいいと思います。
              </div>
              <div>
                IBDの治療の目的のひとつに、「寛解期を延長して維持すること」があります。患者さんの病状を一番把握しているのは主治医です。
                先生を信頼して治療に望むことが最も大切なことであり、寛解への近道に繋がると思います。
                どの疾患や難病でも同じだと思いますが、患者さんの治癒を心から願っているのは、家族の他に主治医の先生です。その先生といつも二人三脚で行きたいのですが、ゆっくりお話ができる機会が少ないのも現状です。
              </div>
              <div>
                そんなときにサポートしてくれるのが患者会であり、Gコミュニティだと思います。Gコミュニティでは、①医療の専門家に質問できる。②他のIBD
                患者さんと情報交換できる。③専門家がまとめた治療、栄養などの最新情報を入手できる。など、自分の困ったとき、好きな時間にアクセスすることができます。
              </div>
              <div>
                一人でも多くの患者さんや家族の方が、Gコミュニティを知り、療養生活に取り入れていただければ幸甚です。
              </div>
            </div>
          </div>
          <HeadLabel label={"企業様向けサービス"} />
          <div className="px-3 pb-8 typography">
            <div>
              Gコミュニティに登録している患者さんにご協力いただき、IBD患者さんを対象とした定性・定量調査や共催イベントなどを行っています。
            </div>
            <div className="my-4">
              <div>
                定性・定量調査では、調査設計、質問表作成、リクルート、インタビュー/調査表の配布、インサイト出しまで一括で引き受けております。（リクルート目的のみなどでのコミュニティ活用なども可能です。）
              </div>
              <div>
                日々多くのIBD患者さんと接している専門性の高いスタッフ(MBA、MPHホルダー、製薬企業でのマーケティング担当)によるインサイト出しに定評があります。
              </div>
            </div>
          </div>

          <div id="research" className="text-center my-3 text-lg">
            IBD患者様対象の定性・定量調査
          </div>

          <div className="flex flex-wrap justify-center my-10">
            <div className="p-3 lg:w-1/2">
              <iframe
                title="IBD患者における新型コロナウイルスワクチン接種に関する意識・実態調査"
                src="//www.slideshare.net/slideshow/embed_code/key/bsCawwlxedi9oV"
                scrolling="no"
                className="w-full h-80 md:h-96 border-y border-x mx-auto"
              ></iframe>
              <div className="m-3 text-blue-500 underline">
                <a
                  href="//www.slideshare.net/NoriyukiSuzuki6/ibd-251371456"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  IBD患者における新型コロナウイルスワクチン接種に関する意識・実態調査
                </a>
              </div>
            </div>
            <div className="p-3 lg:w-1/2">
              <iframe
                title="新型コロナウイルスがIBD患者さんに及ぼす影響に関する実態調査(第2回)"
                src="//www.slideshare.net/slideshow/embed_code/key/m5vPlNEuerGoDy"
                scrolling="no"
                className="w-full h-80 md:h-96 border-y border-x mx-auto"
              ></iframe>
              <div className="m-3 text-blue-500 underline">
                <a
                  href="//www.slideshare.net/NoriyukiSuzuki6/ibd2-251371442"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  新型コロナウイルスがIBD患者さんに及ぼす影響に関する実態調査(第2回)
                </a>
              </div>
            </div>

            <div className="p-3 lg:w-1/2">
              <iframe
                title="新型コロナウイルスがIBD患者さんに及ぼす影響に関する実態調査(第2回)"
                src="//www.slideshare.net/slideshow/embed_code/key/xeeylA4ejnrstg"
                scrolling="no"
                className="w-full h-80 md:h-96 border-y border-x mx-auto"
              ></iframe>
              <div className="m-3 text-blue-500 underline">
                <a
                  href="//www.slideshare.net/NoriyukiSuzuki6/ibd-251371431"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  新型コロナウイルスがIBD患者さんに及ぼす影響に関する実態調査
                </a>
              </div>
            </div>
          </div>

          <div className="text-center my-3 text-lg">
            IBD患者様を対象としたイベントの企画・実行
          </div>

          <div className="px-3 pb-8 typography">
            イベントについては、企業様、患者さん双方のニーズを踏まえた企画を検討・実施します。SNS活用による患者さんの集客にも定評があります。
          </div>

          <div className="text-center my-3 text-lg">過去のイベント実施例</div>

          <div className="mb-20">
            <div className="mt-5 mx-5 pb-3 border-b border-gray-400">
              <div className="mb-2 py-1">
                <span className="text-center px-3 mr-3 rounded-full text-[#0aa7b1] border-x border-y border-[#0aa7b1] inline-block w-24">
                  Event
                </span>
              </div>
              <div className="typography">
                IBDとともに働き続けるコツ(田辺三菱製薬株式会社様)
              </div>
              <div className="mt-2 font-semibold">
                <a
                  href="https://prtimes.jp/main/html/rd/p/000000021.000055131.html"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  詳しくはこちら →
                </a>
              </div>
            </div>

            <div className="mt-5 mx-5 pb-3 border-b border-gray-400">
              <div className="mb-2 py-1">
                <span className="text-center px-3 mr-3 rounded-full text-[#0aa7b1] border-x border-y border-[#0aa7b1] inline-block w-24">
                  Event
                </span>
              </div>
              <div className="typography">
                世界IBDデーにおける「IBDレシピ」SNS投稿企画(EAファーマ株式会社様)
              </div>
              <div className="mt-2 font-semibold">
                <a
                  href="https://prtimes.jp/main/html/rd/p/000000016.000055131.html"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  詳しくはこちら →
                </a>
              </div>
            </div>
          </div>

          <HeadLabel label={"企業・病院様向けサービス"} />
          <div className="px-3 pb-8 typography">
            製薬企業様向け・病院様向けのデジタルヘルス事業開発、デジタルマーケティング支援を行っています。デジタルマーケティング支援では、サイト構成、配信記事作成なども行っています。{" "}
          </div>
        </div>
      </div>

      <Contact />
    </Layout>
  )
}
